<script>
  import Header from "../components/Header.svelte";
  import Footer from "../components/Footer.svelte";

  function prevent(event) {
    event.preventDefault();
  }
</script>

<svelte:head>
  <title>Kontakt</title>
</svelte:head>

<Header />
<main on:load={window.scrollTo(0, 0)}>
  <section>
    <h1>Kontakta mig!</h1>
    <!--Contact form-->
    <form action="POST" data-netlify="true">
      <input name="Namn" placeholder="* Namn" type="text" required /><br />
      <input name="email" placeholder="* E-mail" type="email" required /><br />
      <input name="number" placeholder="Telefon Nummer" type="number" /><br />
      <textarea name="message" placeholder="* Meddelande" required />
      <div class="field">
        <div data-netlify-recaptcha="true" />
      </div>
      <input
        class="btn submit"
        type="submit"
        value="Skicka"
      />
    </form>
    <!--Social media block-->
    <div>
      <hr />
      <h2>Jag finns även på sociala medier</h2>
      <!--Instagram-->
      <a href="https://www.instagram.com/ol.hantverk/" target="_blank"
        ><i class="fa-brands fa-instagram fa-3x" /></a
      >
      <!--Facebook-->
      <a href="https://www.facebook.com/oskar.schutzer" target="_blank"
        ><i class="fa-brands fa-facebook-square fa-3x" /></a
      >
      <h3>Och även på Github</h3>
      <!--Github-->
      <a href="https://github.com/Stisset" target="_blank"
        ><i class="fa-brands fa-github-square fa-3x" /></a
      >
    </div>
  </section>
</main>
<Footer />

<style>
  h1 {
    font-size: 32px;
    padding-top: 40px;
    padding-bottom: 20px;
  }

  h2,
  h3 {
    padding: 20px 0;
  }

  hr {
    max-width: 460px;
    width: 100%;
    margin-top: 30px;
    border: 1px solid #4a4a4a;
  }

  form {
    max-width: 500px;
    width: 100%;
    margin: auto;
    padding: 0 2%;
  }

  input,
  textarea {
    max-width: 500px;
    width: 100%;
    text-indent: 15px;
    padding: 10px 0;
    margin: 5px 0;
    border-radius: 5px;
    border: none;
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
  }

  .btn {
    color: #f3f3f3;
    background-color: #ce3d57;
    cursor: pointer;
    text-indent: 0;
  }

  .btn:hover {
    background-color: #832a3a;
    transition: all 0.3s ease;
  }

  .fa-brands {
    color: #f3f3f3;
    margin: 0 10px;
  }

  .fa-brands:hover {
    color: #ce3d57;
    transition: all 0.3s ease;
  }

  @media (max-width: 800px) {
    main {
      padding: 0;
    }
  }
</style>
