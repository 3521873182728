<script>
  import Header from "../components/Header.svelte";
  import Footer from "../components/Footer.svelte";
  import Modal from "../components/Modal.svelte";

  import { onDestroy } from "svelte";
  import { imgArray } from "../../scripts/imgArray";
  import modalStore from "../stores/modalStore.js";

  let images = imgArray;
  let modal;

  //Subscribe to modal store and redefine variable
  export let image = "";
  const unsubscribe = modalStore.subscribe((data) => {
    image = data;
  });

  //Unsubscribe on component unmount
  onDestroy(unsubscribe);

  //listen for click and collect the src attr
  const handleImageClick = (event) => {
    let image = event.target.src;
    //fire the show function inside of modal component
    modal.show();
    //save src attr in store
    modalStore.update((data) => {
      return image;
    });
  };
</script>

<svelte:head>
  <title>Galleri</title>
</svelte:head>

<Header />
<main on:load={window.scrollTo(0, 0)}>
  <h1>Galleri</h1>
  <!-- Image modal -->
  <Modal bind:this={modal} />
  <div class="grid">
    <div class="img-container">
      <!-- Paste all images from the image array -->
      {#each images as image}
        <img
          on:click={handleImageClick}
          id={image.id}
          src={image.src}
          alt={image.id}
        />
      {/each}
    </div>
  </div>
</main>
<Footer />

<style>
  main {
    max-width: 90%;
  }

  .img-container {
    width: 100%;
    overflow: hidden;
    margin-bottom: -1.875em;
    -webkit-column-count: 4;
    -webkit-column-gap: 1.875em;
    -moz-column-count: 4;
    -moz-column-gap: 1.875em;
    column-count: 4;
    column-gap: 1.875em;
  }

  img {
    cursor: pointer;
    background-color: #616161;
    margin-bottom: 1.875em;
    -moz-page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
    -o-column-break-inside: avoid;
    -ms-column-break-inside: avoid;
    page-break-inside: avoid;
  }

  h1 {
    padding-top: 30px;
  }

  .grid {
    display: grid;
    gap: 30px;
    padding: 30px 0;
  }

  @media (max-width: 1000px) {
    .img-container {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
  }

  @media (max-width: 600px) {
    .img-container {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
  }

  @media (max-width: 470px) {
    .img-container {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    }
  }
</style>
