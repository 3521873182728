<script>
  import Header from "../components/Header.svelte";
  import Footer from "../components/Footer.svelte";
</script>

<svelte:head>
  <title>Erfarenheter</title>
</svelte:head>

<Header />
<main on:load={window.scrollTo(0, 0)}>
  <section>
    <h2>Verktyg jag använder med vana</h2>
    <div class="card">
      <div class="html">
        <i
          title="Hypertext Markup Language 5"
          class="fa-brands fa-html5 fa-6x"
        />
        <h3>HTML</h3>
      </div>
      <div class="css">
        <i
          title="Cascading Style Sheets 3"
          class="fa-brands fa-css3-alt fa-6x"
        />
        <h3>CSS</h3>
      </div>
      <div class="js">
        <i title="Javascript" class="fa-brands fa-js-square fa-6x" />
        <h3>Javascript</h3>
      </div>
      <div class="npm">
        <i title="Node Package Manager" class="fa-brands fa-npm fa-6x" />
        <h3>NPM</h3>
      </div>
      <div class="git">
        <i
          title="Global Information Tracker"
          class="fa-brands fa-git-alt fa-6x"
        />
        <h3>GIT</h3>
      </div>
    </div>
    <br />
    <hr />
    <h2>Fler verktyg jag använder</h2>
    <div class="card">
      <div class="11ty">
        <img title="Eleventy" src="../assets/11ty.png" alt="11ty" />
        <h3>Eleventy</h3>
      </div>
      <div class="svelte">
        <img title="Svelte" src="../assets/svelte.png" alt="svelte" />
        <h3>Svelte</h3>
      </div>
      <div class="sass">
        <i title="Sass" class="fa-brands fa-sass fa-6x" />
        <h3>Sass</h3>
      </div>
      <div class="node">
        <i title="Node and Express" class="fa-brands fa-node-js fa-6x" />
        <h3>Node/Express</h3>
      </div>
      <div class="mongodb">
        <img
          title="Mongo Database"
          src="../assets/mongodb.png"
          alt="Mongo DB"
        />
        <h3>Mongo DB</h3>
      </div>
    </div>
    <br />
  </section>
</main>
<Footer />

<style>
  .card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    min-height: 300px;
    max-width: 930px;
    width: 100%;
    margin: auto;
    margin-top: 30px;
    border-radius: 15px;
    background-color: #353535;
  }

  .card div {
    margin: 30px;
  }

  hr {
    width: 90%;
    margin: 20px auto;
    border: 1px solid #4a4a4a;
  }

  h2 {
    margin: 0 5%;
    margin-top: 50px;
    font-size: 32px;
  }

  h3 {
    margin-top: 20px;
  }

  img {
    width: 100px;
  }

  .html {
    color: #e54c21;
  }

  .css {
    color: #0170b9;
  }

  .js {
    color: #ead41c;
  }

  .npm {
    color: #c13534;
  }

  .git {
    color: #e44d30;
  }

  .sass {
    color: #c36192;
  }

  .node {
    color: #529941;
  }

  .angular {
    color: #b9002d;
  }

  .react {
    color: #61dafb;
  }

  @media (max-width: 1000px) {
    main {
      padding: 0;
    }

    .card {
      border-radius: 0px;
      max-width: 100%;
    }
  }
</style>
