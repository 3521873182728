<script>
  import { clickOutside } from "../../scripts/clickOutside";
  import GoTop from "./GoTop.svelte";

  // active class for sidebar navigation
  let active = false;

  // function to remove the active class when clicking outside of the sidebar navigation
  function handleClickOutside(event) {
    active = false;
  }
</script>

<header>
  <nav>
    <!--This block is the left part of the navigation-->
    <div class="left-part-nav">
      <!--Containing burger menu and sidebar navigation-->
      <div class="menu-wrapper">
        <!--Invisible overlay to prevent clickOutside and active interfering-->
        <div id="menu-overlay-btn" class:active />
        <div id="menu" class:active on:click={() => (active = !active)} />
        <div
          id="sidebar"
          use:clickOutside
          on:click_outside={handleClickOutside}
          class:active
        >
          <ul class="sidebar-list">
            <!--Using Svelte SPA router, hashbased routing-->
            <li><a href="/" class="sidebar-link">Hem</a></li>
            <li><a href="#/om-mig" class="sidebar-link">Om Mig</a></li>
            <li>
              <a href="#/erfarenheter" class="sidebar-link">Erfarenheter</a>
            </li>
            <li><a href="#/projekt" class="sidebar-link">Projekt</a></li>
          </ul>
        </div>
      </div>
      <!--Home link-->
      <a class="icon-link" href="/">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 11.0855C4 10.4972 4.25903 9.93873 4.70815 9.55873L10.7082 4.48203C11.4538 3.8511 12.5462 3.8511 13.2919 4.48203L19.2919 9.55873C19.741 9.93873 20 10.4972 20 11.0855V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V11.0855Z"
            stroke=""
            stroke-width="2"
          />
          <rect
            x="9"
            y="14"
            width="6"
            height="6"
            rx="1"
            stroke=""
            stroke-width="2"
          />
        </svg>
      </a>
    </div>

    <GoTop />

    <!--This block is the right part of the navigation-->
    <div class="right-part-nav">
      <!--Gallery link-->
      <a class="icon-link" href="#/galleri">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="4"
            y="4"
            width="16"
            height="16"
            rx="2"
            stroke=""
            stroke-width="2"
          />
          <path
            d="M5 19L12.6227 11.5703C13.3969 10.8156 14.6307 10.8129 15.4082 11.5641L20 16"
            stroke=""
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <circle cx="9" cy="9" r="1" stroke="" stroke-width="2" />
        </svg>
      </a>
      <!--Contact link-->
      <a class="icon-link" href="#/kontakt">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="21"
            y="5"
            width="14"
            height="18"
            rx="2"
            transform="rotate(90 21 5)"
            stroke=""
            stroke-width="2"
          />
          <path
            d="M7 9L10.8531 11.6972C11.5417 12.1792 12.4583 12.1792 13.1469 11.6972L17 9"
            stroke=""
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </a>
    </div>
  </nav>
</header>

<style>
  header {
    position: sticky;
    top: 0px;
    background-color: #1d1d1d;
    border-bottom: 1px solid #4a4a4a;
    width: 100%;
    height: 100px;
    padding: 0 20px;
    z-index: 10;
  }

  nav {
    position: relative;
    max-width: 1000px;
    width: 100%;
    height: 100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .left-part-nav,
  .right-part-nav {
    display: flex;
    align-items: center;
    gap: 70px;
  }

  #menu-overlay-btn.active {
    position: absolute;
    left: 0px;
    cursor: pointer;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    z-index: 1;
  }

  #menu-overlay-btn:hover + #menu {
    background: #ce3d57;
    transition: all 0.3s ease;
  }

  #menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    height: 50px;
    width: 50px;
    background: none;
    border-radius: 50%;
  }

  svg {
    stroke: #f3f3f3;
  }

  svg:hover {
    stroke: #ce3d57;
    transition: all 0.3s ease;
  }

  #menu:hover {
    background: #ce3d57;
    transition: all 0.3s ease;
  }

  #menu::before {
    content: "";
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    width: 25px;
    height: 2px;
    background: white;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  #menu::after {
    content: "";
    position: absolute;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    width: 25px;
    height: 2px;
    background: white;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  #menu.active::before {
    -webkit-transform: translateY(0px) rotate(45deg);
    transform: translateY(0px) rotate(45deg);
  }

  #menu.active::after {
    -webkit-transform: translateY(0px) rotate(-45deg);
    transform: translateY(0px) rotate(-45deg);
  }

  #sidebar {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    height: 100vh;
    background: #1d1d1d;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  #sidebar.active {
    left: 0px;
  }

  .sidebar-list {
    position: relative;
    margin-top: 50px;
  }

  #sidebar li {
    list-style: none;
    display: inline-block;
    text-align: center;
    width: 100%;
    padding: 10px 40px;
    border-radius: 15px 0 0 15px;
  }

  /*#sidebar li:hover {
  background-color: black;
  -webkit-transition: all 0.9s ease;
  transition: all 0.9s ease;
}*/

  .sidebar-link {
    color: white;
    text-decoration: none;
    font-size: 1.5em;
    letter-spacing: 2px;
  }

  #sidebar a {
    display: inline-block;
    width: 100%;
  }

  #sidebar a:hover {
    color: #ce3d57;
    transition: all 0.3s ease;
  }
</style>
