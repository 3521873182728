<script>
  import Header from "../components/Header.svelte";
  import Footer from "../components/Footer.svelte";
</script>

<svelte:head>
  <title>Projekt</title>
</svelte:head>

<Header />
<main on:load={window.scrollTo(0, 0)}>
  <section>
    <!--Projects Card-->
    <div class="card">
      <div class="site-wrapper">
        <!--Project site block-->
        <div class="site-container">
          <div class="left-site-block">
            <h2>Fishing-Sweden.se</h2>
            <p>
              Denna webbsidan har jag gjort åt min svärfar som hyr ut fisketurer
              och stugor. Den är byggd med "11ty" och har Netlify's CMS
              integrerat.
            </p>
            <!--Link to project website-->
            <div class="link-block">
              <a href="https://fishing-sweden.se/" target="_blank" class="link"
                >Länk</a
              >
              <a href="/" class="link">Mer info</a>
            </div>
          </div>
          <div class="right-site-block">
            <img
              src="/assets/frontpage/fishing-sweden.jpg"
              alt="Fishing-sweden screenshot"
            />
          </div>
        </div>

        <hr />

        <!--Project site block-->
        <div class="site-container">
          <div class="left-site-block">
            <h2>Ljungsan.com</h2>
            <p>
              Jag gjorde denna till min och min frus kennel. Webbsidan är byggd
              med Eleventy "11ty", en statisk sidgenerator.
            </p>
            <!--Link to project website-->
            <div class="link-block">
              <a href="https://ljungsan.com/" target="_blank" class="link"
                >Länk</a
              >
              <a href="/" class="link">Mer info</a>
            </div>
          </div>
          <div class="right-site-block">
            <img
              src="/assets/frontpage/ljungsan.jpg"
              alt="Ljungsan screenshot"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<Footer />

<style>
  main {
    padding: 0;
  }

  .card {
    display: flex;
    max-width: 960px;
    width: 100%;
    margin: auto;
    margin-top: 30px;
    border-radius: 15px;
    background-color: #353535;
    color: #f3f3f3;
    padding: 40px;
    gap: 30px;
  }

  hr {
    width: 100%;
    margin: 20px auto;
    border: 1px solid #4a4a4a;
  }

  p {
    margin: 10px auto;
  }

  .link-block {
    width: 300px;
    display: flex;
    justify-content: space-between;
  }

  .site-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .site-container {
    display: flex;
    justify-content: space-between;
  }

  .left-site-block {
    text-align: left;
    max-width: 50ch;
  }

  .right-site-block img {
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
  }

  .link {
    background-color: #ce3d57;
    color: #f3f3f3;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 5px 40px;
    border-radius: 5px;
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
  }

  .link:hover {
    background-color: #832a3a;
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 800px) {
    .card {
      flex-direction: column;
      align-items: center;
      padding: 5%;
      border-radius: 0;
    }

    .site-container {
      flex-direction: column;
      margin: 30px 0;
    }

    .right-site-block img {
      margin-top: 30px;
    }
  }
</style>
