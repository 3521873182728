<script>
  import Header from "../components/Header.svelte";
  import Footer from "../components/Footer.svelte";
</script>

<svelte:head>
  <title>Curriculum Vitae</title>
</svelte:head>

<Header />
<main on:load={window.scrollTo(0, 0)}>
  <section id="summary">
    <img src="./assets/graphics/profil-transparent.png" alt="" />
    <h1>
      <span class="red-cutof">O</span>skar <span class="red-cutof">L</span>jung
    </h1>
    <h3>Om Mig</h3>
    <p id="bio">
      Jag är 28 år gammal, född och uppvuxen i Säffle. Nyfiken och informations
      hungrig skulle man kunna kalla mig. Jag älskar att lära mig nya saker och
      förstå hur allt fungerar, “Kunskap och ambition är nyckeln till framgång”.
      Personlig utveckling är viktigt för mig.
    </p>
    <h3>Kontakt info</h3>
    <div id="contact-info">
      <div class="info-block">
        <i class="fa-solid fa-location-pin fa-2x" />
        <div class="text-block">
          <p>Adress:</p>
          <p>Bolet Lövenborg 21 661-94 Säffle</p>
        </div>
      </div>
      <div class="info-block">
        <div class="info-block">
          <i class="fa-solid fa-phone fa-2x" />
          <div class="text-block">
            <p>Telefon:</p>
            <p>076-0905101</p>
          </div>
        </div>
      </div>
      <div class="info-block">
        <div class="info-block">
          <i class="fa-solid fa-envelope fa-2x" />
          <div class="text-block">
            <p>E-Mail:</p>
            <p>oskar.ljung95@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="detailed">
    <h2>Arbetslivserfarenhet</h2>
    <br />
    <p>
      <strong>Nuvarande Arbete:</strong>
      Tillsvidare anställd som snickare på Moelven Byggmodul sedan 2015. På Moelven
      har jag jobbat med det mesta inom produktion såsom stomme, montering och inrede
      etc.
    </p>
    <br />
    <p>
      Innan Moelven så jobbade jag i Holland hos Gout Beheer i 12 veckor som
      snickare tills projektet var klart.
    </p>
    <hr />
    <h2>Utbildning</h2>
    <br />
    <p>Bygg/anläggnings programmet på Herrgårdsgymnasiet f.om 2011 t.om 2014</p>
    <br />
    <p>Kursledare utbildning hos SV år 2019</p>
    <br />
    <p>Webbutveckling 1 på NTI skolan år 2021</p>
    <br />
    <ul>
      <li>Körkort B</li>
      <li>Liftkort A1-B3</li>
      <li>Truckkort A1-B6</li>
      <li>ADR 1:3</li>
    </ul>
    <hr />
    <h2>Kunskaper</h2>
    <ul>
      <li>HTML, CSS och Javascript</li>
      <li>Eleventy, Svelte, Sass</li>
      <li>Photoshop, Figma och Procreate</li>
      <li>
        Baskunskap inom:
        <ul>
          <li>Node</li>
          <li>Express</li>
          <li>MongoDB</li>
        </ul>
      </li>
    </ul>
    <hr />
    <h2>Språk</h2>
    <br />
    <div class="languages">
      <div class="language">
        <h3>Svenska</h3>
        <progress value="100" max="100" />
      </div>
      <div class="language">
        <h3>Engelska</h3>
        <progress value="80" max="100" />
      </div>
      <div class="language">
        <h3>Nederländska</h3>
        <progress value="50" max="100" />
      </div>
    </div>
    <hr />
    <h2>Hobby & Intresse</h2>
    <br />
    <p>Jag har stort dataintresse, webbutveckling och nätverk etc.</p>
    <br />
    <p>
      Jag sysslar med smide och knivmakeri som hobby, samt håller i
      smideskurser.
    </p>
    <br />
    <p>Jakt och fiske har sedan länge varit ett intresse för mig.</p>
  </section>
</main>
<Footer />

<style>
  main {
    display: flex;
    text-align: left;
    height: 100%;
  }

  progress {
    width: 100px;
  }

  #summary {
    background-color: #383838;
    padding: 75px 30px;
  }

  #detailed {
    background-color: #f3f3f3;
    padding: 30px;
  }

  #detailed p,
  h2,
  h3 {
    color: #383838;
  }

  h1 {
    font-size: 52px;
    text-align: center;
    margin-top: 30px;
    font-weight: bold;
  }

  .red-cutof {
    color: #ce3d57;
  }

  .fa-solid {
    color: #f3f3f3;
  }

  img {
    background-color: #424242;
    max-width: 300px;
    margin: auto;
    box-shadow: 5px 5px 25px 5px rgb(37, 37, 37);
    margin-top: 50px;
    margin-bottom: 50px;
  }

  #bio {
    max-width: 40ch;
    margin: 30px auto;
    text-align: center;
  }

  #contact-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 80px;
  }

  .info-block {
    display: flex;
    gap: 30px;
  }

  .languages {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 950px) {
    main {
      flex-direction: column;
    }
  }

  @media (max-width: 420px) {
    .languages {
      flex-direction: column;
      align-items: center;
    }
    img {
      max-width: 100%;
    }
  }
</style>
