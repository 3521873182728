<script>
  import Header from "../components/Header.svelte";
  import Footer from "../components/Footer.svelte";
</script>

<svelte:head>
  <title>404</title>
</svelte:head>

<Header />
<main on:load={window.scrollTo(0, 0)}>
  <h1><span class="red-cutof">404</span> Sidan kunde inte hittas!</h1>
</main>
<Footer />

<style>
  main {
    height: 63vh;
  }

  h1 {
    padding-top: 70px;
    font-size: 63px;
  }

  .red-cutof {
    color: #ce3d57;
  }
</style>
