<script>
  import Header from "../components/Header.svelte";
  import Footer from "../components/Footer.svelte";
</script>

<svelte:head>
  <title>Om Mig</title>
</svelte:head>

<Header />
<main on:load={window.scrollTo(0, 0)}>
  <section>
    <!--Detailed biography-->
    <h2>Om mig</h2>
    <h3>Vem är jag?</h3>
    <p>
      Jag är 28 år gammal, född och uppvuxen i Säffle. Nyfiken och informations
      hungrig skulle man kunna kalla mig. Jag älskar att lära mig nya saker och
      förstå hur allt fungerar, det går hand i hand med att jag har varit IT
      intresserad sedan jag var en liten grabb, men då var det mest dataspel.
    </p>
    <h3>Hur började det?</h3>
    <p>
      Jag började att skapa saker via datorn, spel, webbsidor etc. när jag var
      runt 15 år gammal. Inga avancerade saker men jag lärde mig mycket och hade
      riktigt skoj. Det var dock inte för än i vuxen ålder som jag förstod att
      jag skulle kunna jobba inom detta, jag hade ju gått ett Bygg/Anläggnings
      program i gymnasiet. Så hur skulle jag bära mig åt för att byta
      yrkesbranch till något som jag verkligen tycker är kul?
    </p>
    <h3>Var är jag nu?</h3>
    <p>
      Jag började med att gå en utbildning för grunderna inom frontend
      webbutveckling, HTML CSS och lite Javascript, samt lagar och riktlinjer
      och en smula cybersäkerhet. Efter det så fortsatte jag på egen hand att
      finslipa det jag lärt mig för att bygga upp en stabil grund inom ämnet.
      Jag har nu vidgat ut spåret och bland annat börjat lära mig lite
      javascript ramverk för att få ett generellt förstånd för hur det fungerar.
    </p>
    <br />
    <hr />
    <!--My hobbies-->
    <h2>Mina intressen</h2>
    <ul>
      <li>Generellt dataintresse</li>
      <li>Hantverk/Slöjd</li>
      <li>Bilar</li>
      <li>Träning</li>
      <li>Jakt</li>
      <li>Pistolskytte</li>
      <li>Fiske</li>
    </ul>
  </section>
</main>
<Footer />

<style>
  section {
    padding: 20px;
  }

  ul {
    text-align: left;
    max-width: 300px;
    width: 100%;
    margin: auto;
    padding-top: 30px;
    font-size: 24px;
    color: #f3f3f3;
  }

  hr {
    width: 100%;
    margin: 20px auto;
    border: 1px solid #4a4a4a;
  }

  h2 {
    margin-top: 30px;
  }

  h3 {
    text-align: left;
    margin: auto;
    margin-top: 30px;
    max-width: 68ch;
  }

  p {
    margin: 10px auto;
    max-width: 80ch;
    text-align: left;
  }

  @media (max-width: 800px) {
    main {
      padding: 0;
    }
  }
</style>
