<script>
  let look;

  //Litsen for a 1000px scroll to set look to true
  window.onscroll = function () {
    scrollFunction();
  };
  function scrollFunction() {
    if (
      document.body.scrollTop > 1000 ||
      document.documentElement.scrollTop > 1000
    ) {
      look = true;
    } else {
      look = false;
    }
  }

  //Scroll to top function
  function goTop() {
    window.scrollTo(0, 0);
  }
</script>

<!-- Show scroll to top buttton if look is set to true -->
{#if look}
  <i on:click={goTop} class="fas fa-arrow-up fa-2x" />
{/if}

<style>
  i {
    color: #f3f3f3;
    cursor: pointer;
  }
</style>
