<script>
  import Router from "svelte-spa-router";

  import Home from "./routes/Home.svelte";
  import Om from "./routes/Om.svelte";
  import Erfarenheter from "./routes/Erfarenheter.svelte";
  import Projekt from "./routes/Projekt.svelte";
  import Galleri from "./routes/Galleri.svelte";
  import Kontakt from "./routes/Kontakt.svelte";
  import CV from "./routes/CV.svelte";

  import NotFound from "./routes/404.svelte";

  let routes = {
    "/": Home,
    "/om-mig": Om,
    "/erfarenheter": Erfarenheter,
    "/projekt": Projekt,
    "/galleri": Galleri,
    "/kontakt": Kontakt,
    "/curriculum-vitae": CV,
    "*": NotFound,
  };
</script>

<div>
  <Router {routes} />
</div>
