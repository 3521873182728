<script>
  let year = new Date().getFullYear();
</script>

<footer>
  <div class="footer-wrapper">
    <div class="left-block">
      <h3 class="mb-20">Kontakta mig</h3>
      <a href="#/kontakt">Kontakt info</a>
      <a href="https://www.instagram.com/ol.hantverk/" target="_blank"
        >Instagram</a
      >
      <a href="https://www.facebook.com/oskar.schutzer" target="_blank"
        >Facebook</a
      >
    </div>

    <hr />

    <div class="right-block">
      <h3 class="mb-20">Byggd i Svelte</h3>
      <a href="#/404">Koden till sidan</a>
      <a href="#/projekt">Projekt</a>
      <a href="https://github.com/Stisset" target="_blank">Github</a>
    </div>
  </div>
  <p id="copyright" class="tac">© {year} Oskar Ljung</p>
</footer>

<style>
  footer {
    display: grid;
    place-items: center;
    background-color: #1d1d1d;
    border-top: 1px solid #4a4a4a;
    width: 100%;
    height: 250px;
  }

  .footer-wrapper {
    display: flex;
  }

  .left-block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 30px;
    color: #f3f3f3;
  }

  a {
    color: #f3f3f3;
    text-decoration: none;
  }

  a:hover {
    color: #ce3d57;
    transition: all 0.3s ease;
  }

  .right-block {
    display: flex;
    flex-direction: column;
    margin: 30px;
    color: #f3f3f3;
  }

  hr {
    margin: 20px auto;
    border: 1px solid #4a4a4a;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .tac {
    text-align: center;
  }

  #copyright {
    color: #f3f3f3;
    margin-bottom: 30px;
  }
</style>
