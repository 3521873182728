<script>
  import modalStore from "../stores/modalStore.js";
  import { clickOutside } from "../../scripts/clickOutside";

  let shown = false;

  //Export show function to be able to fire from galleri component
  export function show() {
    shown = true;
  }

  //Function to hide the modal
  function hide() {
    shown = false;
  }

  //Clicks outside of the image modal hides the modal
  function handleClickOutside(event) {
    shown = false;
  }
</script>

<!-- If shown is true display the modal -->
{#if shown}
  <div class="modal-backing">
    <div class="modal">
      <img
        use:clickOutside
        on:click_outside={handleClickOutside}
        class="modal-img"
        src={$modalStore}
        alt="Bild"
      />
      <button on:click={hide}>Stäng</button>
    </div>
  </div>
{/if}

<style>
  .modal-backing {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 0px;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    z-index: 999;
  }

  button {
    padding: 10px 50px;
    margin: 0 auto;
    width: 100%;
    border-radius: 0px 0px 5px 5px;
    cursor: pointer;
    border: none;
    background-color: #ce3d57;
    color: #f3f3f3;
  }

  button:hover {
    background-color: #832a3a;
    transition: all 0.3s ease;
  }

  .modal {
    margin: 2% auto;
    padding: 0 5%;
  }

  .modal-img {
    max-height: 80vh;
    max-width: 90vw;
  }
</style>
